<!--
--------------------------------------------------------------------------------
<copyright file="BendingPerformance.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <p class="info list-item info-bkg">{{ $t('widget_info_text.info') }}:</p>
    <p class="text info-bkg">{{ $t('widget_info_text.bend_perfm_info_txt') }}</p>
    <details>
      <summary>{{ $t('widget_info_text.bend_time_part') }}</summary>
      <p class="text">{{ $t('widget_info_text.bend_time_part_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.kg_station_plan') }}</summary>
      <p class="text">{{ $t('widget_info_text.kg_station_plan_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.tool_changes') }}</summary>
      <p class="text">{{ $t('widget_info_text.tool_changes_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.part_types') }}</summary>
      <p class="text">{{ $t('widget_info_text.part_types_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.bends_part') }}</summary>
      <p class="text">{{ $t('widget_info_text.bends_part_txt') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.number_of_bends') }}</summary>
      <p class="text">{{ $t('widget_info_text.number_of_bends_txt') }}</p>
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
import TimeFilterInfo from '@/components/Charts/widgetInfo/TimeFilter.vue';
import ShiftFilterInfo from '@/components/Charts/widgetInfo/ShiftFilter.vue';

@Component({
  components: {
    'time-filter': TimeFilterInfo,
    'shift-filter': ShiftFilterInfo,
  },
})
export default class BendingPerformanceInfo extends Vue {}
</script>
